<template>
  <div>
    <div class="hero-main platinum-layout white-sec" :style="style">
      <div class="container">
        <div class="row row-reverse align-items-center">
          <div class="col-sm-12 col-md-6" data-wow-delay="0.5s">
            <div class="events treasury-animation">
              <div class="platinum-move-1" />
              <div class="platinum-move-2" />
              <div class="platinum-move-3" />
              <div class="platinum-move-4" />
              <div class="platinum-move-5" />
              <div class="top-part">
                <div class="coin-icon" />
              </div>
              <!-- <div class="millde-part" /> -->
              <div class="base">
                <div class="lines">
                  <span class="l-1" />
                  <span class="l-2" />
                  <span class="l-3" />
                  <span class="l-4" />
                  <span class="l-5" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 mobile-center">
            <h1>Your dream event, <span>delivered!</span></h1>
            <p class="lead">Limitations live only in our minds. But if we use our imaginations, our possibilities become limitless. - "Jamie Paolinetti"</p>
            <div class="hero-btns mt-3">
              <a href="#explore" class="btn el-btn">Explore</a>
              <a href="" class="btn el-btn" @click.prevent="$router.push('/treasury')">Check Treasury</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Hero Section End -->
    <div id="explore" class="token-sale p-tb c-l">
      <div class="container mt-6">
        <div class="text-center"><h2 class="section-heading">Events <span>Allocation</span></h2></div>
        <div class="sub-txt text-center">
          <p>The allocation of our current events is presented below.</p>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h3><span class="strong">Events Address</span></h3>
            <div class="token-allocation-box row">
              <div class="wallet-container col-md-12">
                <h4>Metamask</h4>
                <div class="wallet">
                  <span>{{ ca }}</span>
                  <a href="" @click.prevent="onCopy(ca)"><i class="fa fa-copy" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="sponsors" class="team-section p-b c-l">
      <div class="container">
        <div class="team-member text-center">
          <div class="text-center"><h2 class="section-heading">Meet Our <span>Sponsors</span></h2></div>
          <div class="row">
            <div class="col wow fadeInUp" data-wow-delay="0.1s" :style="sponsorsStyle4">
              <div class="team">
                <div class="team-inner">
                  <img :src="journey" alt="team">
                  <h4>Journey</h4>
                </div>
                <div class="team-social">
                  <ul>
                    <li><a href="https://twitter.com/Journyai_erc" target="_blank"><i class="fab fa-x-twitter" /></a></li>
                    <li><a href="https://t.me/JourneyAiPortal" target="_blank"><i class="fab fa-telegram" /></a></li>
                    <li><a href="https://www.journeyhub.ai" target="_blank"><i class="fa fa-globe" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col wow fadeInUp" :style="sponsorsStyle1">
              <div class="team">
                <div class="team-inner">
                  <img :src="frtc" alt="team">
                  <h4>FRTC</h4>
                </div>
                <div class="team-social">
                  <ul>
                    <li><a href="https://twitter.com/fairtokencake" target="_blank"><i class="fab fa-x-twitter" /></a></li>
                    <li><a href="https://t.me/fairtokencake" target="_blank"><i class="fab fa-telegram" /></a></li>
                    <li><a href="https://www.facebook.com/fairtokencake" target="_blank"><i class="fab fa-facebook-f" /></a></li>
                    <li><a href="https://fairtokencake.com" target="_blank"><i class="fa fa-globe" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col wow fadeInUp" data-wow-delay="0.1s" :style="sponsorsStyle2">
              <div class="team">
                <div class="team-inner">
                  <img :src="carmel" alt="team">
                  <h4>Carmel</h4>
                </div>
                <div class="team-social">
                  <ul>
                    <li><a href="https://twitter.com/carmelprotocol" target="_blank"><i class="fab fa-x-twitter" /></a></li>
                    <li><a href="https://t.me/carmelplatform" target="_blank"><i class="fab fa-telegram" /></a></li>
                    <li><a href="https://carmel.io" target="_blank"><i class="fa fa-globe" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col wow fadeInUp" data-wow-delay="0.1s" :style="sponsorsStyle3">
              <div class="team">
                <div class="team-inner">
                  <img :src="nnn" alt="team">
                  <h4>NNN</h4>
                </div>
                <div class="team-social">
                  <ul>
                    <li><a href="https://twitter.com/guardiannnai" target="_blank"><i class="fab fa-x-twitter" /></a></li>
                    <li><a href="https://t.me/NNNTokenGroup" target="_blank"><i class="fab fa-telegram" /></a></li>
                    <li><a href="https://guardiannn.ai" target="_blank"><i class="fa fa-globe" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="events" class="team-section p-b c-l">
      <div class="container">
        <div class="team-member text-center">
          <div class="text-center"><h2 class="section-heading">Upcoming <span>Events</span></h2></div>
          <div class="row mt-4 justify-content-center">
            <div class="col-md-3" />
            <div class="col-md-6 col-12 wow fadeInUp" :style="sponsorsStyle1">
              <div class="team">
                <div class="team-inner">
                  <a href="https://t.me/ElrosCryptoGroup" target="_blank">
                    <img :src="noEvents" alt="team">
                  </a>
                </div>
              </div>
              <a class="btn el-btn mt-4" href="https://t.me/ElrosCryptoGroup" target="_blank">Reach out</a>
            </div>
            <div class="col-md-3" />
          </div>
          <div v-if="false" class="text-center mt-4"><h2 class="section-heading">In progress <span>Events</span></h2></div>
          <div v-if="false" class="row mt-4">
            <div class="col wow fadeInUp" :style="sponsorsStyle1">
              <div class="team">
                <div class="team-inner">
                  <img :src="fit" alt="team">
                </div>
              </div>
              <a class="btn el-btn mt-4" href="https://forms.gle/uHWW7XNdDLu7MCVo6" target="_blank">Register</a>
            </div>
            <div class="col wow fadeInUp" :style="sponsorsStyle1">
              <div class="team">
                <div class="team-inner">
                  <img :src="web" alt="team">
                </div>
              </div>
              <a class="btn el-btn mt-4" href="https://t.me/CryptoGemContact" target="_blank">Contact us</a>
            </div>
          </div>
          <!-- PAST EVENTS -->
          <div class="text-center mt-4"><h2 class="section-heading">Past <span>Events</span></h2></div>
          <div class="container">
            <div class="row d-flex justify-content-center">
              <!-- Get Fit Challenge -->
              <div class="col-md-3 col-xl-2 wow fadeInUp" :style="sponsorsStyle1">
                <div class="team">
                  <div class="team-inner">
                    <a href="https://forms.gle/uHWW7XNdDLu7MCVo6" target="_blank">
                      <img :src="fit" alt="team">
                    </a>
                  </div>
                </div>
                <a class="btn el-btn mt-4 mb-4" href="https://forms.gle/uHWW7XNdDLu7MCVo6" target="_blank">Completed</a>
              </div>
              <!-- Web Dev. -->
              <div class="col-md-3 col-xl-2 wow fadeInUp" :style="sponsorsStyle1">
                <div class="team">
                  <div class="team-inner">
                    <a href="https://t.me/CryptoGemContact" target="_blank">
                      <img :src="web" alt="team">
                    </a>
                  </div>
                </div>
                <a class="btn el-btn mt-4 mb-4" href="https://t.me/CryptoGemContact" target="_blank">Completed</a>
              </div>
              <!-- Elros Party #1 -->
              <div class="col-md-3 col-xl-2 wow fadeInUp" :style="sponsorsStyle1">
                <div class="team">
                  <div class="team-inner">
                    <a href="https://www.youtube.com/watch?v=0yeko0t7zq4" target="_blank">
                      <img :src="party" alt="team">
                    </a>
                  </div>
                </div>
                <a class="btn el-btn mt-4 mb-4" href="https://www.youtube.com/watch?v=0yeko0t7zq4" target="_blank">Completed</a>
              </div>
              <!-- Elros Party #2 -->
              <div class="col-md-3 col-xl-2 wow fadeInUp" :style="sponsorsStyle1">
                <div class="team">
                  <div class="team-inner">
                    <img :src="bw" alt="team">
                  </div>
                </div>
                <a class="btn el-btn mt-4">Completed</a>
              </div>
            </div>
          </div>
          <!--  Completed Poker Battles  -->
          <div class="container">
            <div class="text-center mt-4"><h3 class="section-heading">Poker <span>Battles</span></h3></div>
            <div class="row d-flex justify-content-center">
              <!-- 1st Poker Battle -->
              <div class="col-md-3 col-xl-2 wow fadeInUp" :style="sponsorsStyle1">
                <div class="team">
                  <div class="team-inner">
                    <a href="https://www.pokernow.club/mtt/elros-tournament-cS4IUEe4pu" target="_blank">
                      <img :src="pokerBattle" alt="team">
                    </a>
                  </div>
                </div>
                <p class="mt-4">1st Poker Battle</p>
                <a class="btn el-btn mb-4" href="https://t.me/ElrosCrypto/159" target="_blank">Completed</a>
              </div>
              <!-- 2nd Poker Battle -->
              <div class="col-md-3 col-xl-2 wow fadeInUp" :style="sponsorsStyle1">
                <div class="team">
                  <div class="team-inner">
                    <a href="https://www.pokernow.club/mtt/second-elros-battle-rLrefD9Hkz" target="_blank">
                      <img :src="pokerBattle2" alt="team">
                    </a>
                  </div>
                </div>
                <p class="mt-4">2nd Poker Battle</p>
                <a class="btn el-btn mb-4" href="https://t.me/ElrosCrypto/239" target="_blank">Completed</a>
              </div>
              <!-- 3rd Poker Battle -->
              <div class="col-md-3 col-xl-2 wow fadeInUp" :style="sponsorsStyle1">
                <div class="team">
                  <div class="team-inner">
                    <a href="https://www.pokernow.club/mtt/3rd-elros-battle-qxVcMUL6XH" target="_blank">
                      <img :src="pokerBattle3" alt="team">
                    </a>
                  </div>
                </div>
                <p class="mt-4">3rd Poker Battle</p>
                <a class="btn el-btn mb-4" href="https://t.me/ElrosCryptoGroup/4015" target="_blank">Completed</a>
              </div>
              <!-- 4th Poker Battle -->
              <div class="col-md-3 col-xl-2 wow fadeInUp" :style="sponsorsStyle1">
                <div class="team">
                  <div class="team-inner">
                    <a href="https://t.me/ElrosCrypto/606" target="_blank">
                      <img :src="pokerBattle4" alt="team">
                    </a>
                  </div>
                </div>
                <p class="mt-4">4th Poker Battle</p>
                <a class="btn el-btn mb-4" href="https://t.me/ElrosCrypto/606" target="_blank">Completed</a>
              </div>
              <!-- 5th Poker Battle -->
              <div class="col-md-3 col-xl-2 wow fadeInUp" :style="sponsorsStyle1">
                <div class="team">
                  <div class="team-inner">
                    <a href="https://www.pokernow.club/mtt/5th-elros-battle-lMDMFxWLPk" target="_blank">
                      <img :src="pokerBattle5" alt="team">
                    </a>
                  </div>
                </div>
                <p class="mt-4">5th Poker Battle</p>
                <a class="btn el-btn" href="https://t.me/ElrosCryptoGroup/6529" target="_blank">Completed</a>
              </div>
            </div>
          </div>
          <!--  Completed Poker Tournaments  -->
          <div class="container">
            <div class="text-center mt-4"><h3 class="section-heading">Poker <span>Tournaments</span></h3></div>
            <div class="row d-flex justify-content-center">
              <!-- 1st Poker Tournament -->
              <div class="col-md-3 col-xl-2 wow fadeInUp" :style="sponsorsStyle1">
                <div class="team">
                  <div class="team-inner">
                    <a href="https://www.pokernow.club/mtt/elros-7th-poker-FVw3Umd8-I" target="_blank">
                      <img :src="pokerTournamentv2" alt="team">
                    </a>
                  </div>
                </div>
                <p class="mt-4">1st Poker Tournament</p>
                <a class="btn el-btn mb-4" href="https://t.me/ElrosCrypto/318" target="_blank">Completed</a>
              </div>
              <!-- 2nd Poker Tournament -->
              <div class="col-md-3 col-xl-2 wow fadeInUp" :style="sponsorsStyle1">
                <div class="team">
                  <div class="team-inner">
                    <a href="https://t.me/ElrosCrypto" target="_blank">
                      <img :src="pokerTournamentv2" alt="team">
                    </a>
                  </div>
                </div>
                <p class="mt-4">2nd Poker Tournament</p>
                <a class="btn el-btn mb-4" href="https://t.me/ElrosCrypto" target="_blank">Completed</a>
              </div>
              <!-- 3rd Poker Tournament -->
              <div class="col-md-3 col-xl-2 wow fadeInUp" :style="sponsorsStyle1">
                <div class="team">
                  <div class="team-inner">
                    <a href="https://www.pokernow.club/mtt/elros-tournament-zvesR244k0" target="_blank">
                      <img :src="pokerTournament" alt="team">
                    </a>
                  </div>
                </div>
                <p class="mt-4">3rd Poker Tournament</p>
                <a class="btn el-btn mb-4" href="https://t.me/ElrosCrypto/667" target="_blank">Completed</a>
              </div>
              <!-- 4th Poker Tournament -->
              <div class="col-md-3 col-xl-2 wow fadeInUp" :style="sponsorsStyle1">
                <div class="team">
                  <div class="team-inner">
                    <a href="https://www.pokernow.club/mtt/4th-elros-tournament-FZJJfv46Cb" target="_blank">
                      <img :src="pokerTournament" alt="team">
                    </a>
                  </div>
                </div>
                <p class="mt-4">4th Poker Tournament</p>
                <a class="btn el-btn" href="https://t.me/ElrosCrypto/677" target="_blank">Completed</a>
              </div>
            </div>
          </div>
          <!--  Completed Poker Holders Only  -->
          <div class="container">
            <div class="text-center mt-4 mx-auto"><h3 class="section-heading">Poker <span>Holders Only</span></h3></div>
            <div class="row d-flex justify-content-center">
              <!-- 1st Holders only -->
              <div class="col-md-3 col-xl-2 wow fadeInUp" :style="sponsorsStyle1">
                <div class="team">
                  <div class="team-inner">
                    <a href="https://www.pokernow.club/mtt/freeroll-tournament-q9v9dPfiOa" target="_blank">
                      <img :src="holdersOnly" alt="team">
                    </a>
                  </div>
                </div>
                <p class="mt-4">1st Holders Only</p>
                <a class="btn el-btn mb-4" href="https://t.me/ElrosCrypto/94" target="_blank">Completed</a>
              </div>
              <!-- 2nd Holders Only -->
              <div class="col-md-3 col-xl-2 wow fadeInUp" :style="sponsorsStyle1">
                <div class="team">
                  <div class="team-inner">
                    <a href="https://www.pokernow.club/mtt/elros-holders-only-mrEJejwAdy" target="_blank">
                      <img :src="holdersOnly" alt="team">
                    </a>
                  </div>
                </div>
                <p class="mt-4">2nd Holders Only</p>
                <a class="btn el-btn mb-4" href="https://t.me/ElrosCrypto" target="_blank">Completed</a>
              </div>
              <!-- 3rd Holders Only -->
              <div class="col-md-3 col-xl-2 wow fadeInUp" :style="sponsorsStyle1">
                <div class="team">
                  <div class="team-inner">
                    <a href="https://www.pokernow.club/mtt/3rd-holders-only-rr_rwHdCgW" target="_blank">
                      <img :src="holders3rd" alt="team">
                    </a>
                  </div>
                </div>
                <p class="mt-4 text-sm-md text-lg-xl">3rd Holders Only</p>
                <a class="btn el-btn" href="https://t.me/ElrosCrypto/481" target="_blank">Completed</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { nanoid } from 'nanoid';
  import { copyToClipboard } from '@/utils/copy-to-clipboard';
  import frtc from '@/assets/png/frtc-normal.png';
  import carmel from '@/assets/png/carmel.png';
  import bitDog from '@/assets/png/bitdog-logo.png';
  import nnn from '@/assets/jpg/nnn.jpg';
  import poker from '@/assets/png/poker.png';
  import web from '@/assets/png/web.png';
  import fit from '@/assets/png/fit.png';
  import party from '@/assets/png/party.png';
  import bw from '@/assets/png/bw.png';
  import journey from '@/assets/jpg/journey.jpg';
  import noEvents from '@/assets/png/no-events.png';
  import backgroundBanner from '@/assets/jpg/banner-1.jpg';
  import { WOW } from 'wowjs';
  import pokerBattle from '@/assets/png/poker-fight.png';
  import pokerBattle2 from '@/assets/png/poker-battle-2.png';
  import pokerBattle3 from '@/assets/png/3rd-battle.png';
  import pokerBattle4 from '@/assets/png/4th-battle.png';
  import pokerBattle5 from '@/assets/png/5th-battle.png';
  import pokerBattle6 from '@/assets/jpg/6th-poker-battle.jpg';
  import pokerTournamentv2 from '@/assets/png/poker-tournamentv2.png';
  import pokerTournament from '@/assets/png/poker-tournament.png';
  import holders3rd from '@/assets/png/holder-3rd.png';
  import holdersOnly from '@/assets/png/holders-only.png';

  export default Vue.extend({
    name: 'elros-home',
    data: () => ({
      ca: '0xE6913aeAd753545778dA189ed1f13E90f2F4f32B',
      carmel,
      frtc,
      bitDog,
      nnn,
      journey,
      web,
      fit,
      poker,
      pokerBattle,
      pokerBattle2,
      pokerBattle3,
      pokerBattle4,
      pokerBattle5,
      pokerBattle6,
      pokerTournamentv2,
      pokerTournament,
      holders3rd,
      holdersOnly,
      party,
      bw,
      noEvents,
      style: { backgroundImage: `url(${backgroundBanner})` },
      sponsorsStyle1: { visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp' },
      sponsorsStyle2: { visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp' },
      sponsorsStyle3: { visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' },
      sponsorsStyle4: { visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' },
    }),
    mounted() {
      window.initEventsAnimation();
      /* eslint-disable */
      const wow = new WOW({
        live: false,
      });
      wow.init();
    },
    methods: {
      onCopy(txt) {
        copyToClipboard(txt);
        this.$toasts.toast({
          id: nanoid(),
          message: 'The link has been copied to clipboard',
          title: 'Success',
          intent: 'success',
        });
      },
    },
  });
</script>
